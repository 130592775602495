import React from "react";
import { Link } from "gatsby";
import "../../styles/extras.css";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

const Index = () => (
  <Layout>
    <Seo />

    <div className="mt-20">
      <article className="col-span-full  mx-auto prose prose-lg border rounded-lg p-5">
        <p>nothing here right now</p>
      </article>
    </div>
  </Layout>
);
export default Index;
